import React from "react"
import SEO from "../components/seo"

import { CardLeft, CardRight, Line } from "../components/elements"
import Layout from "../components/layout"
import {
  HeaderPage,
  IntroSection,
  Services,
  ProductDetails,
  ReasonSection,
  ServiceDetail,
} from "../components/structure"

import accounts from "../images/social/accounts.svg"
import caption from "../images/social/caption.svg"
import googleAds from "../images/social/googleAds.svg"
import headerIcon from "../images/social/headerIcon.svg"
import posting from "../images/social/posting.svg"
import reviewPost from "../images/social/reviewPost.svg"

export default function SocialMediaManagement() {
  return (
    <Layout>
      <SEO title="Social Media Management" />
      <HeaderPage
        src={headerIcon}
        color={props => props.theme.procolors.shopDark}
        bgColor={props => props.theme.procolors.shopLight}
        title="Cement your brand and connect with customers."
        tagline="From creating eye-catching content to scheduling posts, we’ll manage all your social platforms."
        catchy="Social Media Management"
      />
      <Line />
      <ServiceDetail>
        <IntroSection
          title="Social Media Management"
          details="We offer social media management as part of our business services. Your social media profiles say a lot about your brand, so leave it to us to utilise the most effective platforms and make them work for you."
          color={props => props.theme.procolors.socialDark}
        />
        <ProductDetails
          to="/contact/"
          title="Our social media management services include:"
          color={props => props.theme.procolors.socialDark}
        >
          <CardLeft
            alt="Facebook, Instagram, and LinkedIn accounts"
            title="Facebook, Instagram, and LinkedIn accounts"
            detail="We’ll manage your accounts across these prominent platforms so you can concentrate on the bones of your business."
            src={accounts}
          />
          <CardRight
            alt="Planning and posting premium content"
            title="Planning and posting premium content"
            detail="Our content service includes planning a content calendar and posting only the best content on behalf of your brand."
            src={posting}
          />
          <CardLeft
            alt="Social media captions"
            title="Social media captions"
            detail="Using a skilled team of writers, we create captions that will catch the eye of your target audience and inspire action."
            src={caption}
          />
          <CardRight
            alt="PPC and Google Ads"
            title="PPC and Google Ads"
            detail="Leverage PPC and Google Ads on social media to bring more awareness and opportunities to your business."
            src={googleAds}
          />
          <CardLeft
            alt="Social media review management"
            title="Social media review management"
            detail="Our review management services will evaluate your position on social media and create the best plan to help you increase your following and engagement."
            src={reviewPost}
          />
        </ProductDetails>
        <ReasonSection
          borderColor={props => props.theme.procolors.socialLight}
          title="Why Do You Need Social Media Management?"
          details="Social media began as a way of keeping up with friends and family. Today, it is a powerful business tool that can increase your brand awareness and expand your company. While your business should run social media accounts to remain accessible to your audience, it’s not always easy to manage your own channels."
          details1="With our social media management services, we create and run accounts across various social media channels that will increase the online visibility of your business.  A well-maintained social media account will allow you to connect with your customers, promote your products and services, and continue to tell your brand’s story."
        />
      </ServiceDetail>
      <Line />
      <Services />
    </Layout>
  )
}
